import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardTitle
} from "mdb-react-ui-kit";
import { renderListItems, renderDoiItem } from "./ReferenceAnalysisReviewUtils";

const ReferenceAnalysisDataReview = ({ data }) => {
  const retractionWatch = data?.screening_outcomes?.find((x) => x.type === "retractionWatch")?.items ?? [];
  const doiResolutions = data?.screening_outcomes?.find((x) => x.type === "doiResolutions")?.items ?? [];

  return (
    <>
      {retractionWatch.length > 0 && (
        <MDBCard className="my-2">
          <MDBCardBody>
            <MDBCardHeader>
              <MDBCardTitle className="my-1">Retraction Watch</MDBCardTitle>
            </MDBCardHeader>
            {renderListItems(retractionWatch, renderDoiItem)}
          </MDBCardBody>
        </MDBCard>
      )}

      {doiResolutions.length > 0 && (
        <MDBCard className="my-2">
          <MDBCardBody>
            <MDBCardHeader>
              <MDBCardTitle className="my-1">DOI Analysis</MDBCardTitle>
            </MDBCardHeader>
            {renderListItems(doiResolutions, renderDoiItem)}
          </MDBCardBody>
        </MDBCard>
      )}
    </>
  );
};

export default ReferenceAnalysisDataReview;
