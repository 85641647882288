import { MDBRow } from "mdb-react-ui-kit";
import { renderCardSection, renderAnyHitDetails, renderBadActorDetails } from "./WatchlistDataReviewUtils.js";
import { getWatchlistData } from "./watchlistUtils";

const WatchlistDataReview = ({ data }) => {
  const watchlistData = getWatchlistData(data);

  return (
    <MDBRow>
      {renderCardSection(
        watchlistData.watchlistFakeNamesOrEmailAddresses,
        "Fake names or email addresses",
        renderAnyHitDetails
      )}
      {renderCardSection(
        watchlistData.watchlistFakeAffiliationNames,
        "Fake affiliation names",
        renderAnyHitDetails
      )}
      {renderCardSection(
        watchlistData.watchlistFakeEmailDomains,
        "Fake email domains",
        renderAnyHitDetails
      )}
      {renderCardSection(
        watchlistData.watchlistBadActors,
        "Bad actors",
        renderBadActorDetails
      )}
      {renderCardSection(
        watchlistData.watchlistMetadataSuspects,
        "Metadata suspects",
        renderAnyHitDetails
      )}
      {renderCardSection(
        watchlistData.watchlistManuscriptsOfferedForSale,
        "Manuscripts offered for sale",
        renderAnyHitDetails
      )}
      {renderCardSection(
        watchlistData.watchlistSuspectArticles,
        "Suspect articles",
        renderAnyHitDetails
      )}
      {renderCardSection(
        watchlistData.watchlistChatGPTOutput,
        "GenAI response",
        renderAnyHitDetails
      )}
    </MDBRow>
  );
};

export default WatchlistDataReview;
