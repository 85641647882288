import { ModalWrapper, ReviewModalGeneralInfoCard } from "../../../components/ModalWrapper";
import ClearskiesDataReview from "./ClearskiesDataReview";

export const ClearskiesReviewModal = ({ contentData, onClose }) => {
  const isOpen = !!contentData;

  const handleCloseModal = () => {
    onClose();
  };

  return (
    <ModalWrapper
      title={"Clearskies"}
      isOpen={isOpen}
      onClose={handleCloseModal}
    >
      <ReviewModalGeneralInfoCard data={contentData} />
      <ClearskiesDataReview data={contentData} />
    </ModalWrapper>
  );
};
