import dayjs from "dayjs";
import { MDBCol, MDBRow, MDBTypography } from "mdb-react-ui-kit";

export const ReviewModalGeneralInfoCard = ({ data }) => {
  return (
    <div className="px-4 py-3 mb-4 bg-light rounded">
      {data?.timestamp && (
        <MDBRow className="mb-2">
          <MDBCol size="3">
            <MDBTypography tag="h6" className="mb-0">
              {/* <MDBIcon icon="calendar-alt" className="me-2" /> */}
              Received:
            </MDBTypography>
          </MDBCol>
          <MDBCol>
            <MDBTypography tag="h6" className="mb-0">
              {dayjs(data?.timestamp).format("DD MM YYYY")}
            </MDBTypography>
          </MDBCol>
        </MDBRow>
      )}

      {data?.journal_name && (
        <MDBRow className="mb-2">
          <MDBCol size="3">
            <MDBTypography tag="h6" className="mb-0">
              {/* <MDBIcon icon="newspaper" className="me-2" /> */}
              Journal Name:
            </MDBTypography>
          </MDBCol>
          <MDBCol>
            <MDBTypography tag="h6" className="mb-0">
              {data?.journal_name}
            </MDBTypography>
          </MDBCol>
        </MDBRow>
      )}

      {data?.id && (
        <MDBRow className="mb-2">
          <MDBCol size="3">
            <MDBTypography tag="h6" className="mb-0">
              {/* <MDBIcon icon="id-card" className="me-2" /> */}
              Manuscript ID:
            </MDBTypography>
          </MDBCol>
          <MDBCol>
            <MDBTypography tag="h6" className="mb-0">
              {data?.manuscript_id}
            </MDBTypography>
          </MDBCol>
        </MDBRow>
      )}

      {data?.title && (
        <MDBRow className="mb-2">
          <MDBCol size="3">
            <MDBTypography tag="h6" className="mb-0">
              {/* <MDBIcon icon="book" className="me-2" /> */}
              Title:
            </MDBTypography>
          </MDBCol>
          <MDBCol>
            <MDBTypography tag="h6" className="mb-0">
              {data?.title}
            </MDBTypography>
          </MDBCol>
        </MDBRow>
      )}
    </div>
  );
};
