export const getItemsByType = (contentData, type) => {
  return contentData?.screening_outcomes?.find((x) => x.type === type)?.items ?? [];
};

export const getWatchlistData = (contentData) => {
  return {
    watchlistFakeNamesOrEmailAddresses: getItemsByType(contentData, "watchlistFakeNamesOrEmailAddresses"),
    watchlistFakeEmailDomains: getItemsByType(contentData, "watchlistFakeEmailDomains"),
    watchlistFakeAffiliationNames: getItemsByType(contentData, "watchlistFakeAffiliationNames"),
    watchlistBadActors: getItemsByType(contentData, "watchlistBadActors"),
    watchlistMetadataSuspects: getItemsByType(contentData, "watchlistMetadataSuspects"),
    watchlistManuscriptsOfferedForSale: getItemsByType(contentData, "watchlistManuscriptsOfferedForSale"),
    watchlistSuspectArticles: getItemsByType(contentData, "watchlistSuspectArticles"),
    watchlistChatGPTOutput: getItemsByType(contentData, "watchlistChatGPTOutput"),
  };
};
