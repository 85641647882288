import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardTitle
} from "mdb-react-ui-kit";
import { renderFeetOfClayDetails } from "./PubpeerDataReviewUtils";

const PubpeerDataReview = ({ data, withSubHeader = false }) => {
  const feetOfClayDetector = data?.screening_outcomes?.find((x) => x.type === "feetOfClayDetector")?.items ?? [];

  return (
    <>
      {feetOfClayDetector.length > 0 && (
        <MDBCard className="my-2">
          {withSubHeader && (
            <MDBCardHeader>
              <MDBCardTitle className="my-1">Pubpeer</MDBCardTitle>
            </MDBCardHeader>
          )}
          <MDBCardBody>
            {feetOfClayDetector.map((item, index) => renderFeetOfClayDetails(item, index))}
          </MDBCardBody>
        </MDBCard>
      )}
    </>
  );
};

export default PubpeerDataReview;
