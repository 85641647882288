export const token = `${process.env.REACT_APP_TOKEN}`;
export const wstoken = `${process.env.REACT_APP_WSTOKEN}`;
export const apiBaseUrl = `${process.env.REACT_APP_API_BASE_URL}`;

export const ITEMS_PER_PAGE = 25;
export const errorMessage = "Error, something went wrong";
export const successMessage = "Action completed successfully";

export const notificationsTime = 3000;

export const defaultColor = "#4285F4";
