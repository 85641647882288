import { useMemo, useState, useEffect } from "react";
import DataTable from "../../../components/table/DataTable";
import { columns } from "../columns";
import Pagination from "../../../components/Pagination/Pagination";
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import SpinnerOverlay from "../../../components/SpinnerOverlay/SpinnerOverlay";
import NoContentMessage from "../../../components/NoContentMessage/NoContentMessage";
import dayjs from "dayjs";
import SearchInput from "../../../components/SearchInput/SearchInput";
import { TooltipButton } from "../components/TooltipButton";
import { DuplicateSubmissionReportModal } from "../../../components";
import useScreenedManuscripts from "../hooks/useScreenedManuscripts";
import { AmbientScreeningReviewModal } from "../components/AmbientScreeningReviewModal";
import { getAmbientScreeningSubmissionById } from "../../../helpers";

export const UnderReviewManuscriptsTab = () => {
  const [selectedDupSubItemIndex, setSelectedDupSubItemIndex] = useState(null);
  const [selectedAmbientScreeningItemIndex, setSelectedAmbientScreeningItemIndex] = useState(null);
  const [modalContentData, setModalContentData] = useState(null);

  const {
    data,
    isLoading,
    setIsLoading,
    error,
    pageCount,
    currentPage,
    setCurrentPage,
    searchTerm,
    setSearchTerm
  } = useScreenedManuscripts({ tab: 'under_review_manuscripts' });

  const handleDupSubIconClick = (index) => {
    setSelectedDupSubItemIndex(index);
  }

  const handleAmbientScreeningIconClick = (index) => {
    setSelectedAmbientScreeningItemIndex(index);
  }

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const underReviewManuscriptsWithActions = useMemo(() => {
    return data?.map((manuscript, i) => ({
      ...manuscript,
      timestamp: dayjs(manuscript.timestamp).format("DD MM YYYY"),
      signals: (
        <TooltipButton
          key={i}
          manuscript={manuscript}
          handleDupSubIconClick={() => handleDupSubIconClick(i)}
          handleAmbientScreeningIconClick={() => handleAmbientScreeningIconClick(i)}
        />
      ),
    }));
  }, [data]);

  const handleSearchFetch = async (value) => {
    const trimmedValue = value.trim();
    if (!searchTerm && !trimmedValue) return;
    setSearchTerm(trimmedValue);
  };

  const fetchAmbientScreeningDataIfNeeded = async (index) => {
    const item = data[index];
    if (!item || !item.signals.some(signal => signal !== "duplicateSubmissions")) {
      setModalContentData(item);
      return;
    }
    if (item.persistent_id) {
      setIsLoading(true);
      try {
        const { data: fetchedData } = await getAmbientScreeningSubmissionById(item.persistent_id);
        setModalContentData(fetchedData);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (selectedDupSubItemIndex !== null) {
      setModalContentData(data[selectedDupSubItemIndex]);
    } else {
      setModalContentData(null);
    }
  }, [selectedDupSubItemIndex]);

  useEffect(() => {
    if (selectedAmbientScreeningItemIndex !== null) {
      fetchAmbientScreeningDataIfNeeded(selectedAmbientScreeningItemIndex);
    } else {
      setModalContentData(null);
    }
  }, [selectedAmbientScreeningItemIndex]);

  return (
    <SpinnerOverlay active={isLoading} centerOnScreen={true}>
      {selectedDupSubItemIndex !== null && modalContentData && (
        <DuplicateSubmissionReportModal
          contentData={data[selectedDupSubItemIndex]}
          onClose={() => setSelectedDupSubItemIndex(null)}
        />
      )}
      {selectedAmbientScreeningItemIndex !== null && modalContentData && (
        <AmbientScreeningReviewModal
          contentData={modalContentData}
          onClose={() => setSelectedAmbientScreeningItemIndex(null)}
        />
      )}

      <MDBRow className="mb-2 justify-content-end">
        <MDBCol sm={8} md={6} lg={4}>
          <SearchInput
            placeholder="Search by manuscript ID or title"
            setValue={setSearchTerm}
            onSubmit={handleSearchFetch}
          />
        </MDBCol>
      </MDBRow>
      {error && <ErrorMessage />}
      {!error && !isLoading && !data?.length && <NoContentMessage />}
      {!error && !!data?.length && (
        <>
          <DataTable
            columns={columns}
            rows={underReviewManuscriptsWithActions}
          />
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={pageCount}
            currentPage={currentPage}
          />
        </>
      )}
    </SpinnerOverlay>
  );
};
