import {
  MDBContainer,
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import AllManuscriptsTab from "./tabs/AllManuscriptsTab";
import MatchedManuscriptsTab from "./tabs/MatchedManuscriptsTab";
import TriggerManuscriptsTab from "./tabs/TriggerManuscriptsTab";
import { CountCard } from "../../components";
import { showErrorToast, getDuplicateSubmissionsCount } from "../../helpers";
import SpinnerOverlay from "../../components/SpinnerOverlay/SpinnerOverlay";

export const tabs = {
  all: "all",
  matched: "matched",
  triggers: "tiggers",
};

const DuplicateSubmissions = () => {
  const [currentTab, setCurrentTab] = useState(tabs.all);

  const [isLoading, setIsLoading] = useState(false);

  const [manuscriptsCount, setManuscriptsCount] = useState(null);

  useEffect(() => {
    const fetchCardData = async () => {
      try {
        setIsLoading(true);
        const {
          data: { total },
        } = await getDuplicateSubmissionsCount();
        setManuscriptsCount(total);
      } catch (error) {
        showErrorToast();
      } finally {
        setIsLoading(false);
      }
    };

    fetchCardData();
  }, []);

  return (
    <MDBContainer className="my-5">
      <SpinnerOverlay active={isLoading}>
        <div className="d-flex justify-content-center m-5">
          <CountCard
            count={manuscriptsCount ?? 0}
            title={"Matches"}
            subtitle={"Current matches"}
          />
        </div>
      </SpinnerOverlay>
      <MDBTabs className="mb-3">
        <MDBTabsItem onClick={() => setCurrentTab(tabs.all)}>
          <MDBTabsLink active={currentTab === tabs.all}>All</MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem onClick={() => setCurrentTab(tabs.matched)}>
          <MDBTabsLink active={currentTab === tabs.matched}>
            Matched (first submission)
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem onClick={() => setCurrentTab(tabs.triggers)}>
          <MDBTabsLink active={currentTab === tabs.triggers}>
            Matching (trigger submission)
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>
      <MDBTabsContent>
        <MDBTabsPane show={currentTab === tabs.all}>
          <AllManuscriptsTab />
        </MDBTabsPane>
        <MDBTabsPane show={currentTab === tabs.matched}>
          <MatchedManuscriptsTab />
        </MDBTabsPane>
        <MDBTabsPane show={currentTab === tabs.triggers}>
          <TriggerManuscriptsTab />
        </MDBTabsPane>
      </MDBTabsContent>
    </MDBContainer>
  );
};

export default DuplicateSubmissions;
