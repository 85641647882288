import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardTitle
} from "mdb-react-ui-kit";
import { renderListItems, renderClearSkiesItem } from "./ClearskiesDataReviewUtils";

const ClearskiesDataReview = ({ data, withSubHeader = false }) => {
  const clearSkiesStatus = data?.screening_outcomes?.find((x) => x.type === "clearSkiesStatus")?.items ?? [];

  return (
    <>
      {clearSkiesStatus.length > 0 && (
        <MDBCard className="my-2">
          <MDBCardBody>
            {withSubHeader && (
              <MDBCardHeader>
                <MDBCardTitle className="my-1">Clearskies</MDBCardTitle>
              </MDBCardHeader>
            )}
            {renderListItems(clearSkiesStatus, renderClearSkiesItem)}
          </MDBCardBody>
        </MDBCard>
      )}
    </>
  );
};

export default ClearskiesDataReview;
