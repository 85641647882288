export const columns = [
  {
    label: "Received",
    field: "timestamp",
  },
  {
    label: "Journal",
    field: "journal_name",
  },
  {
    label: "Manuscript ID",
    field: "manuscript_id",
  },
  {
    label: "Manuscript title",
    field: "title",
  },
  { label: "Status", field: "status" },
];
