import { MDBBtn, MDBIcon, MDBTooltip } from "mdb-react-ui-kit";

const TooltipButton = ({
  manuscript,
  onViewOldReport,
  handleDupSubIconClick,
  handleAmbientScreeningIconClick
}) => {
  const showDuplicateSubmissionIcon = manuscript?.signals?.includes("duplicateSubmissions");
  const showAmbientScreeningIconClick = manuscript?.signals?.some((signal) => signal !== "duplicateSubmissions");
  // const showWatchlistIcon = manuscript?.signals?.some((signal) => signal.startsWith("watchlist"));
  // const showSuspiciousIcon = manuscript?.signals?.some((signal) =>
  //     signal !== "duplicateSubmissions" && !signal.startsWith("watchlist")
  // );

  return (
    <div className="d-flex">
      {showDuplicateSubmissionIcon && (
        <MDBTooltip
          tag="div"
          title="Match in Duplicate submissions, click for report"
          placement="top"
        >
          <MDBBtn
            className="mx-2"
            color="primary"
            floating
            onClick={handleDupSubIconClick}
          >
            <MDBIcon fas icon="copy" />
          </MDBBtn>
        </MDBTooltip>
      )}
      {showAmbientScreeningIconClick && (
        <MDBTooltip
          tag="div"
          title="Watchlist match found, click for details"
          placement="top"
        >
          <MDBBtn
            className="mx-2"
            color="secondary"
            floating
            onClick={handleAmbientScreeningIconClick}
          >
            <MDBIcon far icon="eye" />
          </MDBBtn>
        </MDBTooltip>
      )}
      {/* {showSuspiciousIcon && (
        <MDBTooltip
          tag="div"
          title="Suspicious element, click for report"
          placement="top"
        >
          <MDBBtn
            className="mx-2"
            color="warning"
            floating>
            onClick={handleSuspiciousIconClick}
            <MDBIcon fas icon="exclamation-triangle" />
          </MDBBtn>
        </MDBTooltip>
      )} */}
    </div>
  );
};

export { TooltipButton };
