import { MDBBtn, MDBIcon, MDBTooltip } from "mdb-react-ui-kit";
import DataTable from "../../../components/table/DataTable";
import { columns } from "../columns";
import { useState } from "react";
import { UnmarkActionModal } from "../components/UnmarkActionModal";
import { ConfirmActionModal } from "../components/ConfirmActionModal";
import SpinnerOverlay from "../../../components/SpinnerOverlay/SpinnerOverlay";
import useDuplicateSubmissions from "../hooks/useDuplicateSubmissions";
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";
import Pagination from "../../../components/Pagination/Pagination";
import dayjs from "dayjs";
import NoContentMessage from "../../../components/NoContentMessage/NoContentMessage";
import { DuplicateSubmissionReportModal } from "../../../components";

const MatchedManuscriptsTab = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const [isUnmarkModalOpen, setIsUnmarkModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const { data, isLoading, error, pageCount } = useDuplicateSubmissions({
    currentPage,
    type: "matched",
  });

  const handleRowClick = (index) => {
    if (index === null) {
      return;
    }
    setSelectedRowIndex(index);
  }

  const dataWithActions = data?.map((element, index) => ({
    ...element,
    timestamp: dayjs(element.timestamp).format("DD MM YYYY"),
    clickEvent: () => handleRowClick(index),
    action: (
      <div className="d-flex">
        {element.isUnmarkAction && (
          <MDBTooltip tag="div" title="Unmark" placement="top">
            <MDBBtn
              color="secondary"
              floating
              onClick={(event) => {
                event.stopPropagation();
                setIsUnmarkModalOpen(true);
              }}
              className="mx-2"
            >
              <MDBIcon fas icon="xmark" />
            </MDBBtn>
          </MDBTooltip>
        )}
        {element.isConfirmAction && (
          <MDBTooltip tag="div" title="Confirm" placement="top">
            <MDBBtn
              color="secondary"
              floating
              onClick={(event) => {
                event.stopPropagation();
                setIsConfirmModalOpen(true);
              }}
              className="mx-2"
            >
              <MDBIcon fas icon="check" />
            </MDBBtn>
          </MDBTooltip>
        )}
      </div>
    ),
  }));

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  return (
    <SpinnerOverlay active={isLoading}>
      {/* <UnmarkActionModal
        itemId={isUnmarkModalOpen}
        onSuccessSubmit={() => { }}
        onClose={() => setIsUnmarkModalOpen(false)}
      />
      <ConfirmActionModal
        itemId={isConfirmModalOpen}
        onSuccessSubmit={() => { }}
        onClose={() => setIsConfirmModalOpen(false)}
      /> */}
      <DuplicateSubmissionReportModal
        contentData={data[selectedRowIndex]}
        onClose={() => setSelectedRowIndex(null)}
      />
      {error && <ErrorMessage />}
      {!error && !isLoading && data?.length === 0 && <NoContentMessage />}
      {!error && data?.length > 0 && (
        <>
          <DataTable columns={columns} rows={dataWithActions} />
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={pageCount}
            currentPage={currentPage}
          />
        </>
      )}
    </SpinnerOverlay>
  );
};

export default MatchedManuscriptsTab;
