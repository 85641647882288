import {
  MDBTypography,
  MDBRow,
  MDBCol,
  MDBCard
} from "mdb-react-ui-kit";
import Parser from "html-react-parser";
import styled from "styled-components";

export const HrefWrapper = styled.span`
  a:link {
    color: black;
    background-color: yellow;
    text-decoration: none;
  }

  a:hover {
    color: black;
    background-color: yellow;
    text-decoration: underline;
  }

  a:visited {
    color: black;
    background-color: yellow;
    text-decoration: none;
  }
`;

export const BottomMargin = styled.div`
  margin-bottom: 15px;
`;

export const renderIndicationDisplay = (item, index) => {
  return (
    <MDBRow className="mt-4" key={index}>
      {item?.sentence ? (
        <>
          <MDBCol md="9">
            <MDBCard className="torture_phrase_card px-3 py-4">
              {item.sentence.map((row, idx) => (
                <div key={idx}>
                  <HrefWrapper>{Parser(row)}</HrefWrapper>
                  <BottomMargin />
                </div>
              ))}
            </MDBCard>
          </MDBCol>

          <MDBCol md="3">
            <MDBTypography style={{ color: "grey" }} variant="h6">
              <>Indication {(index + 1).toString()}</>
            </MDBTypography>
            {item?.expectedText ? (
              <MDBTypography style={{ color: "#6699cc" }}>
                <>
                  Established term:
                  <br />
                  &#34;{item.expectedText}&#34;
                </>
              </MDBTypography>
            ) : null}
          </MDBCol>
        </>
      ) : (
        <div className="ps-2">None found</div>
      )}
    </MDBRow>
  );
};
