import { useState, useEffect, useCallback } from "react";
import { ITEMS_PER_PAGE } from "../../../constants";
import {
  showErrorToast,
  getAllDuplicateSubmissions,
  getAllAmbientScreeningSubmissions
} from "../../../helpers";

const useScreenedManuscripts = ({ tab = 'under_review_manuscripts' }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [pageCount, setPageCount] = useState(0);

  const [fetchParams, setFetchParams] = useState({
    currentPage: 0,
    searchTerm: '',
    tab,
  });

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setError(false);
    try {
      const { currentPage, searchTerm, tab } = fetchParams;
      let reports, total;
      if (tab === 'under_review_manuscripts') {
        ({ data: { reports = [], total = 0 } = {} } = await getAllDuplicateSubmissions(
          currentPage * ITEMS_PER_PAGE + 1,
          ITEMS_PER_PAGE,
          searchTerm
        ));
      } else {
        ({ data: { manuscripts: reports = [], total = 0 } = {} } = await getAllAmbientScreeningSubmissions(
          currentPage * ITEMS_PER_PAGE + 1,
          ITEMS_PER_PAGE,
          searchTerm
        ));
      }
      setData(reports);
      setPageCount(Math.ceil(total / ITEMS_PER_PAGE));
    } catch (err) {
      showErrorToast();
      setError(true);
    } finally {
      setIsLoading(false);
    }
  }, [fetchParams]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateFetchParams = (newParams) => {
    setFetchParams(prev => ({
      ...prev,
      ...newParams,
      currentPage: newParams.currentPage !== undefined ? newParams.currentPage : prev.currentPage
    }));
  };

  return {
    data,
    isLoading,
    setIsLoading,
    error,
    pageCount,
    currentPage: fetchParams.currentPage,
    searchTerm: fetchParams.searchTerm,
    setSearchTerm: (term) => updateFetchParams({ searchTerm: term, currentPage: 0 }),
    setCurrentPage: (page) => updateFetchParams({ currentPage: page }),
    fetchData
  };
};

export default useScreenedManuscripts;
