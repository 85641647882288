import { useState, useEffect, useCallback } from "react";
import { ITEMS_PER_PAGE } from "../../../constants";
import {
  showErrorToast,
  getDuplicateSubmissionsReportsByType,
} from "../../../helpers";

const useDuplicateSubmissions = ({
  currentPage = 0,
  doInitialFetch = true,
  type,
}) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [pageCount, setPageCount] = useState(0);

  const [searchTerm, setSearchTerm] = useState("");

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setError(false);
    try {
      const { data: { reports = [], total = 0 } = {} } = await getDuplicateSubmissionsReportsByType(
          currentPage * ITEMS_PER_PAGE + 1,
          ITEMS_PER_PAGE,
          type
        );
        setData(reports);
        setPageCount(Math.ceil(total / ITEMS_PER_PAGE));
    } catch (err) {
      showErrorToast();
      setError(true);
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, searchTerm, type]);

  useEffect(() => {
    if (doInitialFetch) {
      fetchData();
    }
  }, [currentPage, doInitialFetch, fetchData]);

  return {
    data,
    isLoading,
    error,
    pageCount,
    fetchData,
    searchTerm,
    setSearchTerm,
  };
};

export default useDuplicateSubmissions;
