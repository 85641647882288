import { MDBListGroup, MDBListGroupItem } from "mdb-react-ui-kit";

export const renderListItems = (items, renderItem) => {
  return items?.map((item, index) => (
    <MDBListGroup flush key={index} className="mt-4">
      {renderItem(item)}
    </MDBListGroup>
  ));
};

export const renderClearSkiesItem = (item) => (
  <MDBListGroupItem>
    <div>
      <strong>Status:</strong> {item?.value}
    </div>
  </MDBListGroupItem>
);
