import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardTitle
} from "mdb-react-ui-kit";
import { renderIndicationDisplay } from "./TextAnalysisDataReviewUtils";

const TextAnalysisDataReview = ({ data, withSubHeader = false }) => {
  const torturedPhrases = data?.screening_outcomes.find((x) => x.type === "torturedPhrases")?.items ?? [];

  return (
    <>
      {!!torturedPhrases.length && (
        <>
          {withSubHeader && (
            <MDBCard>
              <MDBCardHeader>
                <MDBCardTitle className="my-1">Text analysis</MDBCardTitle>
              </MDBCardHeader>
            </MDBCard>
          )}
          {torturedPhrases.map((item, index) => renderIndicationDisplay(item, index))}
        </>
      )}
    </>
  );
};

export default TextAnalysisDataReview;
